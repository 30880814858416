//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Carousel, Slide } from 'vue-carousel';
import i18n from '@/vueI18n';
import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  components: {
    Carousel,
    Slide,
  },
  async mounted() {
    this.scrollTop();
  },
  methods: {
    playMainVideo() {
      this.videoPlayed = !this.videoPlayed;
      console.log('video', this.videoPlayed);
    },
    doPlayVideo(video) {
      console.log('HELLO');
      this.videoUrl = video + '&autoplay=1';
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    isMobileScreen() {
      return this.$q.screen.lt.md;
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById('header');
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },
  },
  computed: {
    compiledHtml: function () {
      return this.input;
    },
    language() {
      return i18n.locale;
    },
    isRTL() {
      return this.language == 'ar';
    },
  },
  watch: {
    language() {
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('app.home');
    },
  },
  data() {
    return {
      loaded: false,
      mainVideoLoaded: false,
      videoPlayed: false,
      videoUrl:
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D398701907650402%26ref%3Dsharing&width=500&show_text=false&appId=300953405227973&height=280',
      playlist: [
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D398701907650402%26ref%3Dsharing&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D337715886891084&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D335575307146998&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D1229415040567142&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2316357105302766&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D1428380887301539&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D436630986895851&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D355457208437310&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D1065052163685828&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2492059280817674&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2375662736051868&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2070924539874233&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2526137320947419&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D1598823696916775&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D832016123842813&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D675661679534992&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2741318972549067&width=500&show_text=false&appId=300953405227973&height=280',
        'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2323873251225464&width=500&show_text=false&appId=300953405227973&height=280',
      ],
    };
  },
};
